<template>
  <div class="Container volunteer">
    <h4>{{ $t('organization.volunteer.title') }}</h4>
    <p>{{ $t('organization.volunteer.description') }}</p>
    <HaButton class="volunteer__btn" :href="urlJVA" target="_blank" @click="trackCall">{{ $t('organization.volunteer.button') }}</HaButton>
    <p>{{ $t('organization.volunteer.by') }} <img src="https://cdn.helloasso.com/images/partner/logo-jva.png" alt=""/> </p>
  </div>
</template>

<script lang="ts" setup>
import { HaButton, useTracking } from '@ha/components-v3'
  import useStoreData from '@/composables/useStoreData'

const { tracking } = useTracking()
const { organization } = useStoreData()

const urlJVA = "http://api.api-engagement.beta.gouv.fr/r/campaign/65fc47e694edc5f668c3cd5c"

const trackCall = () => {
  const trackInfo = {
    origin : 'Page asso',
    organization_slug : organization.value.organizationSlug,
    activity_field : organization.value.category,
    href : urlJVA
  }
  tracking.track('JVA CTA', trackInfo)
}

</script>

<style lang="scss" scoped>
.volunteer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--hads-color-white);
  color: var(--hads-color-storm);
  border-radius: var(--hads-radius-250);
  box-shadow: $ha-box-shadow-middle;
  padding: var(--hads-spacing-500);
  margin-left: var(--hads-spacing-300);
  margin-right: var(--hads-spacing-300);
  text-align: center;

  @include mediaQuery(600) {
    padding: var(--hads-spacing-300) var(--hads-spacing-250);
  }

  @include mediaQuery(1200) {
    margin-left: auto;
    margin-right: auto;
  }

  :deep(.HaButton-Fill.HaButton-Primary) {
    background-color: #0a0593; // Hexa bc it's only for this partner -> JeVeuxAider.gouv.fr
    margin-top: var(--hads-spacing-300);
    margin-bottom: var(--hads-spacing-300);
  }

  h4 {
    margin-bottom: var(--hads-spacing-200);
  }

  p {
    margin-bottom: 0;

    &:last-of-type {
      display: flex;
      align-items: center;
    }
  }

  img {
    margin-left: var(--hads-spacing-250);
    max-width: 10rem;
    width: 100%;
  }
}
</style>
